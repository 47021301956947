import { makeAutoObservable } from 'mobx';
import { IUser } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { UserApi } from '../api/UserApi';

export class UserStore {
  api: UserApi;
  currentUser: Partial<IUser> | null = null;

  constructor() {
    this.api = new UserApi();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setCurrentUser(user: Partial<IUser>) {
    this.currentUser = user;
  }

  async getUserById(id: string) {
    const user = await this.api.getUserById(id);
    this.setCurrentUser(user);
  }
}
