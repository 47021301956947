import React, { useEffect, useState } from 'react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { useTranslate } from '../../hooks/useTranslate';
import { calculateTimeDifference } from '../../utils/time';
import { BodyText3 } from '../typography/BodyTexts';

type TimerProps = {
  endTime: number;
  format?: 'dhms' | 'hms' | 'ms' | 'hm' | 'dh';
  setAlert?: (alert: boolean) => void;
  style?: React.CSSProperties;
};

export const Timer = ({ endTime, format, style }: TimerProps) => {
  const [timer, setTimer] = useState('');
  const [endingSoon, setEndingSoon] = useState(false);
  const [ended, setEnded] = useState(false);
  const t = useTranslate();

  const setEndTimeStates = () => {
    const { formatted, timeLeft } = calculateTimeDifference(endTime, format);
    const timeLeftInHours = timeLeft / (1000 * 60 * 60);
    setEndingSoon(timeLeftInHours <= 1);
    setEnded(timeLeft <= 0);
    setTimer(formatted);
  };

  useEffect(() => {
    setEndTimeStates();

    const interval = setInterval(() => {
      setEndTimeStates();
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime, format]);

  return (
    <BodyText3
      textTransform="none"
      color={endingSoon ? EncoreColors.Error : ''}
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        ...style,
      }}
    >
      {ended ? t('auction.ended') : timer}
    </BodyText3>
  );
};

export default Timer;
