import React from 'react';
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { ReactComponent as InstagramIcon } from '../../assets/svg/instagram.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/svg/linkedin.svg';
import EncoreLogo from '../../assets/png/encore-logo.png';
import { useTranslate } from '../../hooks/useTranslate';
import { Routes } from '../../router/routes';

const Footer = () => {
  const t = useTranslate();

  return (
    <Box as="footer" p={4} py="96px">
      <Flex
        justify="center"
        align="center"
        borderBottom="1px solid"
        borderColor={EncoreColors.GreyLine}
        pb="70px"
      >
        <Link href="/">
          <Image src={EncoreLogo} alt="encore logo" width={100} />
        </Link>
      </Flex>

      <Flex justify="space-between" align="center" pt="32px">
        <Flex gap="32px">
          <FooterText>{t('footer.copyright')}</FooterText>
          <StyledLink href={Routes.PrivacyPolicy}>
            <FooterText>{t('footer.privacyPolicy')}</FooterText>
          </StyledLink>
          <StyledLink href={Routes.Terms}>
            <FooterText>{t('footer.terms')}</FooterText>
          </StyledLink>
        </Flex>

        <Flex gap="12px">
          <StyledLink href="https://www.instagram.com/buyencore/" isExternal>
            <Box as={InstagramIcon} width={6} height={6} />
          </StyledLink>
          <StyledLink
            href="https://www.linkedin.com/company/encore-marketplace-inc/"
            isExternal
          >
            <Box as={LinkedInIcon} width={6} height={6} />
          </StyledLink>
        </Flex>
      </Flex>
    </Box>
  );
};

const FooterText = styled(Text)`
  font-size: 14px;
  color: ${EncoreColors.GreyText};
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export default Footer;
