import React from 'react';
import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import styled from '@emotion/styled';

import HomePageImage from '../assets/png/HomePageImage.png';
import { Breakpoints } from '../constants/sizes';

const ImageCollage = () => {
  const [isWidth800] = useMediaQuery(`(max-width: ${Breakpoints.Width800})`);

  return (
    <ImageContainer isWidth800={isWidth800}>
      <HomePageContainer src={HomePageImage} alt="HomePageImage" />
    </ImageContainer>
  );
};

const ImageContainer = styled(Box)<{ isWidth800?: boolean }>`
  position: relative;
  width: 55%;
  height: 100%;
  top: ${(props) => (props.isWidth800 ? 'unset' : '-100px')};
`;

const HomePageContainer = styled(Image)`
  height: auto;
  width: 100%;
`;

export default ImageCollage;
