import { io, Socket } from 'socket.io-client';
import { makeAutoObservable } from 'mobx';
import { Events } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { CompleteListing } from '../models/Listing';

export class EventsStore {
  private socket: Socket;

  connected: boolean = false;

  constructor() {
    this.socket = io(process.env.REACT_APP_SOCKET_URL || '', {
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 60000,
      reconnectionAttempts: 1000,
      timeout: 10000,
    });

    console.log('Connecting to sockets');
    this.socket.on('connect', () => {
      this.setConnected(true);
      console.log('Connected to websockets:', this.connected);
    });

    this.socket.on('error', (error) => {
      console.log('Socker error:', error);
    });

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private setConnected(connected: boolean) {
    this.connected = connected;
  }

  private listen(event: string, cb: (data: any) => void) {
    this.socket.on(event, cb);
  }

  listenToListingEvent(
    event: Events,
    listingId: string,
    cb: (data: any) => void,
  ) {
    const listingEvent = ['listing', listingId, event].join('-');
    return this.listen(listingEvent, cb);
  }

  unsubscribeToAllListingEvents(listingId: string) {
    const eventsToUnsubscribe = [
      Events.PaymentSucceeded,
      Events.SotckDecremented,
      Events.NewBid,
      Events.AuctionEnded,
    ];

    eventsToUnsubscribe.forEach((event) => {
      this.unsubscribe(['listing', listingId, event].join('-'));
    });
  }

  unsubscribe(event: string) {
    this.socket.removeAllListeners(event);
  }
}
