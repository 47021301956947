import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';

import { CompleteListing } from '../../models/Listing';
import { AuctionVideoWrapper } from '../../screens/styledComponents';

import Control from './Control';

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const PlayerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ControlContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const StyledVideo = styled.div`
  width: 100%;
  height: 100%;

  video {
    object-fit: fill !important;
    border-radius: 12px;
  }
`;

const formatTime = (time: number): string => {
  if (isNaN(time)) {
    return '00:00';
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  if (hours) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds}`;
  } else {
    return `${minutes}:${seconds}`;
  }
};

interface VideoState {
  playing: boolean;
  muted: boolean;
  volume: number;
  played: number;
  seeking: boolean;
  buffer: boolean;
}

interface VideoPlayerProps {
  listing: CompleteListing;
}

const VideoPlayer = ({ listing }: VideoPlayerProps) => {
  let count = 0;
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const controlRef = useRef<HTMLDivElement>(null);

  const [videoState, setVideoState] = useState<VideoState>({
    playing: true,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    buffer: true,
  });

  const { playing, muted, volume, played, seeking, buffer } = videoState;

  const currentTime: number = videoPlayerRef.current
    ? videoPlayerRef.current.getCurrentTime()
    : 0;
  const duration: number = videoPlayerRef.current
    ? videoPlayerRef.current.getDuration()
    : 0;

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  const playPauseHandler = () => {
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const progressHandler = (state: { played: number }) => {
    if (count > 3 && controlRef.current) {
      controlRef.current.style.visibility = 'hidden';
    } else if (
      controlRef.current &&
      controlRef.current.style.visibility === 'visible'
    ) {
      count += 1;
    }

    if (!seeking) {
      setVideoState({ ...videoState, played: state.played });
    }
  };

  const seekHandler = (value: number) => {
    setVideoState({ ...videoState, played: value / 100 });
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(value / 100);
    }
  };

  const seekMouseUpHandler = (value: number) => {
    setVideoState({ ...videoState, seeking: false });
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(value / 100);
    }
  };

  const volumeChangeHandler = (value: number) => {
    const newVolume = value;
    setVideoState({
      ...videoState,
      volume: newVolume / 100,
      muted: newVolume === 0,
    });
  };

  const volumeSeekUpHandler = (value: number) => {
    const newVolume = value;
    setVideoState({
      ...videoState,
      volume: newVolume / 100,
      muted: newVolume === 0,
    });
  };

  const muteHandler = () => {
    setVideoState({ ...videoState, muted: !videoState.muted });
  };

  const onSeekMouseDownHandler = (e: React.MouseEvent | React.TouchEvent) => {
    setVideoState({ ...videoState, seeking: true });
  };

  const mouseMoveHandler = () => {
    if (controlRef.current) {
      controlRef.current.style.visibility = 'visible';
    }
    count = 0;
  };

  const bufferStartHandler = () => {
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    setVideoState({ ...videoState, buffer: false });
  };

  return (
    <AuctionVideoWrapper>
      <VideoContainer>
        <PlayerWrapper onMouseMove={mouseMoveHandler}>
          <StyledVideo>
            <ReactPlayer
              ref={videoPlayerRef}
              url={listing.video}
              width="100%"
              height="100%"
              playing={playing}
              volume={volume}
              muted={muted}
              onProgress={progressHandler}
              onBuffer={bufferStartHandler}
              onBufferEnd={bufferEndHandler}
              loop
            />
          </StyledVideo>
          <ControlContainer ref={controlRef}>
            <Control
              controlRef={controlRef}
              onPlayPause={playPauseHandler}
              playing={playing}
              played={played}
              onSeek={seekHandler}
              onSeekMouseUp={seekMouseUpHandler}
              volume={volume}
              onVolumeChangeHandler={volumeChangeHandler}
              onVolumeSeekUp={volumeSeekUpHandler}
              mute={muted}
              onMute={muteHandler}
              duration={formatDuration}
              currentTime={formatCurrentTime}
              onMouseSeekDown={onSeekMouseDownHandler}
            />
          </ControlContainer>
        </PlayerWrapper>
      </VideoContainer>
    </AuctionVideoWrapper>
  );
};

export default VideoPlayer;
