import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';
import React from 'react';

import { WebAppColors } from '../constants/colors';
import BlurCircles from '../components/BlurCircles';

export const NavigationContainer = styled(Box)`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const MainComponent = ({ children }: { children: React.ReactNode }) => (
  <Box
    bg={WebAppColors.DarkBackground}
    position="relative"
    overflow="hidden"
    width={'100vw'}
    display={'flex'}
    flexFlow={'column'}
    flexGrow={1}
  >
    <BlurCircles />
    {children}
  </Box>
);
