import React from 'react';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import {
  CardContainer,
  ImageContainer,
  ItemDetailsContainer,
  ItemTitle,
  RatingContainer,
  SpaceBetweenContainer,
  UserImageContainer,
} from './styledComponents';

export const ItemCardSkeleton = () => {
  const colors = {
    startColor: EncoreColors.Gray,
    endColor: EncoreColors.Primary2,
  };

  return (
    <CardContainer>
      <ImageContainer>
        <Skeleton height="200px" {...colors} />
      </ImageContainer>

      <ItemDetailsContainer>
        <ItemTitle>
          <Skeleton height="20px" width="70%" {...colors} />
        </ItemTitle>

        <SpaceBetweenContainer>
          <Skeleton height="20px" width="50px" {...colors} />
          <Skeleton height="20px" width="50px" {...colors} />
        </SpaceBetweenContainer>

        <UserImageContainer>
          <SkeletonCircle size="40px" {...colors} />
          <Skeleton height="20px" width="100px" {...colors} />
        </UserImageContainer>

        <RatingContainer>
          <Skeleton height="20px" width="100px" {...colors} />
        </RatingContainer>
      </ItemDetailsContainer>
    </CardContainer>
  );
};
