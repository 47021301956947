import { useEffect, useRef } from 'react';

export const useLegacyEffect = (callback: () => void) => {
  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) callback();
    return () => {
      effectRan.current = true;
    };
  }, [callback]);
};
