import { extendTheme } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { WebAppColors } from '../constants/colors';

const { Primary, ...otherColors } = EncoreColors;

const theme = extendTheme({
  colors: {
    encore: {
      Primary: {
        200: EncoreColors.Primary,
      },
      ...otherColors,
    },
  },
  fonts: {
    heading: `'Manrope Bold', sans-serif`,
    body: `'Manrope Regular', sans-serif`,
  },
  styles: {
    global: (props: { colorMode: string }) => ({
      body: {
        bg:
          props.colorMode === 'dark'
            ? WebAppColors.DarkBackground
            : WebAppColors.DarkBackground,
      },
      a: {
        color: EncoreColors.Primary,
        _hover: {
          textDecoration: 'underline',
        },
      },
      li: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: `'Manrope Bold', sans-serif`,
      },
    },
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});

export default theme;
