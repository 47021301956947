import React from 'react';
import {
  Box,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

import { WebAppColors } from '../../constants/colors';
import { useTranslate } from '../../hooks/useTranslate';
import EncoreLogo from '../../assets/png/encore-logo.png';
import { Heading3 } from '../typography/Headlines';
import { BodyText1 } from '../typography/BodyTexts';
import { ReactComponent as QRCode } from '../../assets/svg/qr-code.svg';
import { useStore } from '../../hooks/useStore';

const DownloadAppPopup = () => {
  const t = useTranslate();
  const {
    uiStore: { isDownloadPopupOpen, closeDownloadPopup },
  } = useStore();

  return (
    <Modal isOpen={isDownloadPopupOpen} onClose={closeDownloadPopup} isCentered>
      <ModalOverlay />
      <StyledModalContent>
        <Image src={EncoreLogo} alt="encore logo" width={140} />
        <Box>
          <Heading3 textAlign={'center'}>
            {t('common.downloadTheApp')}
            <br />
            {t('common.forThe')}
            <span style={{ color: EncoreColors.Primary }}>
              {' '}
              {t('common.fullExperience')}
            </span>
          </Heading3>
        </Box>
        <QRContainer>
          <Box
            as={QRCode}
            padding={'10px'}
            borderRadius={'12px'}
            w={'180px'}
            backgroundColor={WebAppColors.GrayBackground}
          />
          <BodyText1>{t('common.scanQRCode')}</BodyText1>
        </QRContainer>
      </StyledModalContent>
    </Modal>
  );
};

const StyledModalContent = styled(ModalContent)`
  padding: 48px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  border-radius: 24px;
  border: 2px solid ${EncoreColors.White};
  background-color: ${WebAppColors.Black60};
  box-shadow:
    0 12px 0 0 ${WebAppColors.GreenBlur},
    0 27px 0 0 ${WebAppColors.GreenBlur};
  backdrop-filter: blur(46px);
  min-width: 615px; // TODO: change to responsive
`;

const QRContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
`;

export default observer(DownloadAppPopup);
