import styled from '@emotion/styled';
import { Badge, Box, Flex, Image } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { WebAppColors } from '../../constants/colors';
import { BodyText1, BodyText3, BodyText4 } from '../typography/BodyTexts';

export const AuctionCardBadge = styled(Badge)`
  position: absolute;
  top: 4%;
  left: 4%;
  background: ${WebAppColors.Gray1};
  color: ${EncoreColors.White};
  border-radius: 8px;
  padding: 4px 8px;
  justify-content: center;
  backdrop-filter: blur(2px);
`;

export const CardContainer = styled(Flex)`
  flex-direction: column;
  background: transparent;
  gap: 12px;
  justify-content: center;
  max-width: 100%;
  width: 100%;
`;

export const ImageContainer = styled(Box)<{ height?: string; width?: string }>`
  position: relative;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${WebAppColors.Black0};
    transition: background-color 0.3s ease;
  }

  &:hover:before {
    background-color: ${WebAppColors.Black10};
  }
`;

export const ItemImage = styled(Image)<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  border-radius: 12px;
`;

export const ItemTitle = styled(BodyText1)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 800;
  white-space: nowrap;

  @media (max-width: 1080px) {
    font-size: 14px;
    line-height: ${(props) => props.lineHeight || '20px'};
  }
`;

export const ItemDetailsContainer = styled(Flex)`
  flex-direction: column;
  gap: 12px;
`;

export const SpaceBetweenContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

export const CardBadge = styled(Badge)<{ alert?: boolean }>`
  background: ${(props) =>
    props.alert ? WebAppColors.Red15 : WebAppColors.Gray1};
  color: ${EncoreColors.White};
  border-radius: 12px;
  padding: 6px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 50%;
`;

export const UserImageContainer = styled(Box)`
  display: flex;
  gap: 8px;
  width: 60%;
  align-items: center;
`;

export const RatingContainer = styled(Box)`
  display: flex;
  gap: 8px;
  width: 35%;
  align-items: center;
  justify-content: flex-end;
`;

export const UserImageWrapper = styled(Box)<{
  size?: string;
  minSize?: string;
}>`
  position: relative;
  width: ${(props) =>
    `max(${props.minSize || '10px'}, min(${props.size || '10%'}, 18px))`};
  padding: ${(props) =>
    `max(${props.minSize || '10px'}, min(${props.size || '10%'}, 18px))`};
  border-radius: 50%;
  overflow: hidden;
`;

export const UserImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const ItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: 15%;
`;

export const SellerItemInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 60%;
`;

export const PriceContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const GreenText = styled(BodyText3)`
  font-size: 15px;
  color: ${EncoreColors.Primary};
`;

export const BadgesContainer = styled(Box)`
  display: flex;
  gap: 9px;
  width: 100%;
`;

export const SellerCardName = styled(BodyText4)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 800;
  white-space: nowrap;

  @media (max-width: 1080px) {
    font-size: 10px;
    line-height: ${(props) => props.lineHeight || '12px'};
  }
`;
