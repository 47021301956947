import styled from '@emotion/styled';
import { Box, Image } from '@chakra-ui/react';

import { WebAppColors } from '../../constants/colors';

export const Container = styled.div`
  padding: 80px;

  @media (max-width: 800px) {
    padding: 20px;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 62px 40px;
  border-bottom: 1px solid ${WebAppColors.Gray15};
  margin-bottom: 20px;
  width: 100%;
  gap: 55px;

  @media (max-width: 1080px) {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  @media (max-width: 650px) {
    align-items: flex-start;
    border-bottom: none;
  }
`;

export const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: flex-start;

  @media (max-width: 1080px) {
    gap: 30px;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 650px) {
    gap: 12px;
    justify-content: space-between;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;

  @media (max-width: 1080px) {
    width: 80px;
    height: 80px;
  }
`;

export const Avatar = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const UserInfo = styled.div``;

export const RatingSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

export const StarIcon = styled(Box)`
  margin-left: 8px;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SellerStatsContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  margin-top: 0;

  @media (max-width: 800px) {
    gap: 28px;
    justify-content: center;
    margin-bottom: 18px;
    margin-top: 18px;
  }

  @media (max-width: 650px) {
    justify-content: flex-start;
  }
`;

export const FlexContainer = styled(Box)`
  display: flex;
  gap: 6px;
  align-items: center;
  flex-direction: row;

  @media (max-width: 800px) {
    gap: 0;
    align-items: flex-start;
    flex-direction: column;
  }
`;
