import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { WebAppColors } from '../../constants/colors';
import { ReactComponent as EncoreLogo } from '../../assets/svg/small-logo.svg';
import { BodyText1, BodyText2 } from '../typography/BodyTexts';
import PrimaryButton from '../buttons/PrimaryButton';

const MobileBanner = () => {
  return (
    <Box
      as="header"
      bg={WebAppColors.DarkBackground}
      padding="10px 22px"
      position="sticky"
      bottom="0"
      zIndex="2024"
    >
      <Flex justify="space-between" align="center">
        <Flex align="center" gap={3}>
          <Box as={EncoreLogo} width={10} height={10} />
          <BodyText2 fontWeight="700">Open in the app</BodyText2>
        </Flex>
        <PrimaryButton
          green
          style={{
            borderRadius: '20px',
            padding: '10px 20px',
          }}
        >
          <BodyText1 fontWeight="700">Encore App</BodyText1>
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default MobileBanner;
