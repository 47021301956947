import React from 'react';
import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { observer } from 'mobx-react-lite';

import { Heading1, Heading5 } from '../components/typography/Headlines';
import { useTranslate } from '../hooks/useTranslate';
import PrimaryButton from '../components/buttons/PrimaryButton';
import AuctionsPreview from '../components/itemBars/AuctionsPreview';
import { useStore } from '../hooks/useStore';
import { Breakpoints } from '../constants/sizes';

import {
  HomeScreenWrapper,
  InfoWrapper,
  PrimaryTextWrapper,
} from './styledComponents';
import ImageCollage from './ImageCollage';

const HomeScreen = () => {
  const t = useTranslate();
  const {
    uiStore: { openDownloadPopup },
  } = useStore();

  const [isWidth800, isWidth650] = useMediaQuery([
    `(max-width: ${Breakpoints.Width800})`,
    `(max-width: ${Breakpoints.Width650})`,
  ]);

  return (
    <Box>
      <Flex width="100vw">
        <HomeScreenWrapper isWidth800={isWidth800}>
          <InfoWrapper isWidth650={isWidth650}>
            <PrimaryTextWrapper>
              <Heading1>{t('homepage.buySell')}</Heading1>
              <Heading1 color={EncoreColors.Primary}>
                {t('homepage.collectibles')}
              </Heading1>
              <Heading1>{t('homepage.withStories')}</Heading1>
            </PrimaryTextWrapper>
            <Heading5>{t('homepage.description')}</Heading5>
            <Flex justifyContent={'flex-start'}>
              <PrimaryButton
                mt={5}
                green
                variant="solid"
                padding={'27px 35px'}
                onClick={openDownloadPopup}
              >
                <Heading5>{t('common.downloadApp')}</Heading5>
              </PrimaryButton>
            </Flex>
          </InfoWrapper>
          {!isWidth650 && <ImageCollage />}
        </HomeScreenWrapper>
      </Flex>
      <AuctionsPreview />
    </Box>
  );
};

export default observer(HomeScreen);
