import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { WebAppColors } from '../../constants/colors';
import { BodyText2 } from '../typography/BodyTexts';
import { useTranslate } from '../../hooks/useTranslate';
import { useStore } from '../../hooks/useStore';

import PrimaryButton from './PrimaryButton';

const SliderWrapper = styled(Box)`
  display: flex;
  padding: 4px 16px 4px 4px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${EncoreColors.Primary};
  background: ${WebAppColors.Green2};
`;

interface SliderProps {
  price: number;
}

const Slider = ({ price }: SliderProps) => {
  const t = useTranslate();
  const {
    uiStore: { openDownloadPopup },
  } = useStore();

  return (
    <SliderWrapper>
      <PrimaryButton
        green
        style={{
          borderRadius: '10px',
          padding: '8px 55px',
        }}
        onClick={openDownloadPopup}
      >
        {t('auction.bid')}
      </PrimaryButton>
      <BodyText2 fontWeight="700">{`$${price}`}</BodyText2>
    </SliderWrapper>
  );
};
export default Slider;
