import React, { useState } from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { FiClock } from 'react-icons/fi';
import { ListingType } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { useNavigate } from 'react-router';

import { BodyText3, BodyText4 } from '../typography/BodyTexts';
import { CompleteListing } from '../../models/Listing';
import { useTranslate } from '../../hooks/useTranslate';
import { images } from '../images/images';
import { ReactComponent as Star } from '../../assets/svg/star.svg';
import { Routes } from '../../router/routes';
import { useStore } from '../../hooks/useStore';

import {
  AuctionCardBadge,
  CardBadge,
  CardContainer,
  ImageContainer,
  ItemDetailsContainer,
  ItemImage,
  ItemTitle,
  RatingContainer,
  SpaceBetweenContainer,
  UserImage,
  UserImageContainer,
  UserImageWrapper,
} from './styledComponents';
import Timer from './Timer';

interface ItemCardProps {
  listing: CompleteListing;
}

const ItemCard = ({ listing }: ItemCardProps) => {
  const {
    listingStore: { setCurrentListing },
  } = useStore();
  const t = useTranslate();
  const navigate = useNavigate();

  const [alert, setAlert] = useState(false);

  const onCardClick = () => {
    navigate(`${Routes.Listing}/${listing.id}`);
    setCurrentListing(listing);
  };

  return (
    <CardContainer key={`listing-${listing.id}`}>
      <ImageContainer onClick={onCardClick}>
        <AuctionCardBadge>
          <BodyText4>{`$${listing.price}`}</BodyText4>
        </AuctionCardBadge>
        <ItemImage src={listing.thumbnail} alt="Auction Item" height="auto" />
      </ImageContainer>

      <ItemDetailsContainer>
        <ItemTitle>{listing.title}</ItemTitle>

        <SpaceBetweenContainer>
          {listing.listingType === ListingType.AUCTION ? (
            <>
              <CardBadge alert={alert}>
                <Icon as={FiClock} />
                <Timer
                  endTime={listing.endTime ? listing.endTime : 0}
                  format="dhms"
                  setAlert={setAlert}
                />
              </CardBadge>

              <CardBadge>
                <BodyText3
                  textTransform="none"
                  fontWeight="800"
                >{`🔥 ${listing.auctionMessages.length} ${t('common.bids')}`}</BodyText3>
              </CardBadge>
            </>
          ) : (
            <>
              <UserImageContainer>
                <UserImageWrapper minSize={'12px'}>
                  <UserImage
                    src={
                      listing.user?.avatar
                        ? listing.user?.avatar
                        : images.defaultPicture
                    }
                    alt="User Profile Image"
                  />
                </UserImageWrapper>
                <ItemTitle>{listing.user?.userName}</ItemTitle>
              </UserImageContainer>
              <RatingContainer>
                <Box as={Star} width={4} height={4} />
                <BodyText3 textTransform="none">
                  {`${listing.user?.avgRate || 0} (${
                    listing.user?.totalRatings || 0
                  })`}
                </BodyText3>
              </RatingContainer>
            </>
          )}
        </SpaceBetweenContainer>
      </ItemDetailsContainer>
    </CardContainer>
  );
};

export default ItemCard;
