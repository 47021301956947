import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IUser } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useMediaQuery } from '@chakra-ui/react';

import OpenInAppPopup from '../components/common/OpenInAppPopup';
import { getSellerLink } from '../utils/adjustLinking';
import { Container } from '../components/seller/styledComponents';
import SellerHeader from '../components/seller/SellerHeader';
import SellerListingsTabs from '../components/seller/SellerListingsTabs';
import { useStore } from '../hooks/useStore';
import LoadingAnimation from '../components/lottie/LoadingAnimation';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { BodyText2 } from '../components/typography/BodyTexts';
import { useTranslate } from '../hooks/useTranslate';
import { Breakpoints } from '../constants/sizes';

const SellerProfileScreen = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [openInAppPopupLink, setOpenInAppPopupLink] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const {
    userStore: { currentUser, getUserById, setCurrentUser },
    listingStore: { clearUserListings },
  } = useStore();
  const t = useTranslate();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sharerId = queryParams.get('sharerId');

    (async () => {
      if (id) {
        setLoading(true);
        await getUserById(id);
        setLoading(false);
      }
    })();

    if (id && sharerId) {
      const link = getSellerLink(id, sharerId);
      setOpenInAppPopupLink(link);
    }

    return () => {
      clearUserListings();
      setCurrentUser({} as IUser);
    };
  }, [id]);

  const [isWidth1080] = useMediaQuery([
    `(max-width: ${Breakpoints.Width1080})`,
  ]);

  return (
    <Container>
      {!isWidth1080 && (
        <PrimaryButton
          transparent
          leftIcon={<ArrowBackIcon boxSize={6} />}
          onClick={() => navigate(-1)}
          style={{
            justifyContent: 'flex-start',
            padding: '0 16px 0 0',
            width: 'fit-content',
          }}
        >
          <BodyText2 fontWeight="800">{t('common.back')}</BodyText2>
        </PrimaryButton>
      )}
      <LoadingAnimation loading={loading} />
      {!loading && currentUser && (
        <>
          <SellerHeader currentUser={currentUser as IUser} />
          <SellerListingsTabs />
        </>
      )}
      {openInAppPopupLink && <OpenInAppPopup linkToOpen={openInAppPopupLink} />}
    </Container>
  );
};

export default observer(SellerProfileScreen);
