import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';
import debounce from 'lodash/debounce';

import { ReactComponent as SearchIcon } from '../assets/svg/search-icon.svg';
import { WebAppColors } from '../constants/colors';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { BodyText2 } from '../components/typography/BodyTexts';
import { useTranslate } from '../hooks/useTranslate';
import { useStore } from '../hooks/useStore';
import ItemCard from '../components/cards/ItemCard';
import { CompleteListing } from '../models/Listing';
import { Routes } from '../router/routes';
import loadingAnimation from '../assets/animations/encore_loading.json';
import { AuctionPageType } from '../constants/types';
import LoadingAnimation from '../components/lottie/LoadingAnimation';

import {
  AuctionsGridContainer,
  AuctionsPageActionsWrapper,
  AuctionsPageWrapper,
  TitleText,
} from './styledComponents';

const AuctionsScreen = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    listingStore: {
      getPopularAuctions,
      getRecentBuyNowListings,
      getEndingSoonAuctions,
    },
  } = useStore();

  const [listings, setListings] = useState<CompleteListing[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [isScrolling, setIsScrolling] = useState(false);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);
  const isFetching = useRef(false);
  const prevScrollPosition = useRef(0);
  const prevDocumentHeight = useRef(0);

  const searchParams = new URLSearchParams(location.search);
  const type =
    (searchParams.get('type') as AuctionPageType) ||
    AuctionPageType.popularAuctions;

  useEffect(() => {
    (async () => {
      await fetchAuctions(0, searchString);
    })();
  }, [searchString, type]); // Trigger re-fetch when `type` changes

  const fetchAuctions = async (pageNo: number, searchString: string) => {
    if (isFetching.current) return;
    isFetching.current = true;

    setLoading(true);

    prevScrollPosition.current = window.scrollY;
    prevDocumentHeight.current = document.documentElement.scrollHeight;

    let newAuctions = [];
    switch (type) {
      case AuctionPageType.endingSoon || AuctionPageType.auctions:
        newAuctions = await getEndingSoonAuctions({
          pageNo,
          limit: 30,
          searchString,
        });
        break;
      case AuctionPageType.recentBuyItNow || AuctionPageType.buyItNow:
        newAuctions = await getRecentBuyNowListings({
          pageNo,
          limit: 30,
          searchString,
        });
        break;
      case AuctionPageType.popularAuctions:
      default:
        newAuctions = await getPopularAuctions({
          pageNo,
          limit: 30,
          searchString,
        });
        break;
    }

    const duplicatedAuctions = [...newAuctions];

    if (duplicatedAuctions.length < 30) {
      setHasMore(false);
    }

    setTimeout(() => {
      setListings((prevListings) => {
        if (pageNo === 0) {
          return duplicatedAuctions;
        }
        return [...prevListings, ...duplicatedAuctions];
      });

      setLoading(false);
      isFetching.current = false;

      const newDocumentHeight = document.documentElement.scrollHeight;
      const heightDifference = newDocumentHeight - prevDocumentHeight.current;

      setIsScrolling(true);

      window.scrollTo({
        top: pageNo === 0 ? 0 : prevScrollPosition.current + heightDifference,
        behavior: 'smooth',
      });

      setTimeout(() => {
        setIsScrolling(false);
      }, 500);
    }, 1000);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchString(value);
      setPage(0);
      setHasMore(true);
    }, 300),
    [],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        const target = entries[0];
        if (
          target.isIntersecting &&
          hasMore &&
          !isFetching.current &&
          !isScrolling
        ) {
          const nextPage = page + 1;
          setPage(nextPage);
          await fetchAuctions(nextPage, searchString);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      },
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (observer && sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [page, searchString, hasMore, loading, isScrolling]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <AuctionsPageWrapper>
      <AuctionsPageActionsWrapper>
        <PrimaryButton
          transparent
          leftIcon={<ArrowBackIcon boxSize={6} />}
          onClick={() => navigate(Routes.Home)}
        >
          <BodyText2 fontWeight="800">{t('common.back')}</BodyText2>
        </PrimaryButton>
        <InputGroup w="50%">
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} width={6} height={6} />
          </InputLeftElement>
          <Input
            type="search"
            onChange={handleSearchChange}
            placeholder={t('common.search')}
            variant="outline"
            border="none"
            background={WebAppColors.Gray1}
            color={EncoreColors.LightGray}
            _placeholder={{ color: EncoreColors.LightGray }}
          />
        </InputGroup>
        <Box />
      </AuctionsPageActionsWrapper>
      <TitleText>{t(`auctions.${type}`)}</TitleText>

      <AuctionsGridContainer ref={gridRef}>
        {!loading &&
          listings.map((listing, index) => (
            <ItemCard key={`${listing.id}-${index}`} listing={listing} />
          ))}
      </AuctionsGridContainer>
      <div ref={sentinelRef} style={{ height: '20px', marginTop: '20px' }} />
      <LoadingAnimation loading={loading} />
    </AuctionsPageWrapper>
  );
};

export default observer(AuctionsScreen);
