import React, { useState, useEffect } from 'react';
import { Icon, IconButton, useMediaQuery } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import Slider from 'react-slick';
import { observer } from 'mobx-react-lite';

import { useTranslate } from '../../hooks/useTranslate';
import { CompleteListing } from '../../models/Listing';
import { Heading3 } from '../typography/Headlines';
import PrimaryButton from '../buttons/PrimaryButton';
import ItemCard from '../cards/ItemCard';
import { Breakpoints } from '../../constants/sizes';
import LoadingAnimation from '../lottie/LoadingAnimation';
import PrimaryIconButton from '../buttons/PrimaryIconButton';

import {
  ItemBarContainer,
  ItemBarInfoContainer,
  SlideBox,
  SliderContainer,
  StyledNavigatorIconContainer,
} from './styledComponents';

const settings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: false,
  draggable: false,
  responsive: [
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

interface ItemsShowcaseProps {
  title: string;
  onViewAllClick: () => void;
  listings: CompleteListing[];
  loading: boolean;
}

const ItemsShowcase = ({
  title,
  onViewAllClick,
  listings,
  loading,
}: ItemsShowcaseProps) => {
  const t = useTranslate();

  const [slider, setSlider] = useState<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showArrows, setShowArrows] = useState(false);

  const [isWidth800] = useMediaQuery([`(max-width: ${Breakpoints.Width800})`]);

  useEffect(() => {
    const slidesToShow = isWidth800 ? 3 : 5;
    setShowArrows(listings.length > slidesToShow);
  }, [listings.length, isWidth800]);

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    setCurrentSlide(newIndex);
  };

  const slidesToShow = isWidth800 ? 3 : 5; // Adapt the number of visible slides based on screen width
  const hasNextPage = currentSlide < listings.length - slidesToShow;
  const hasPreviousPage = currentSlide > 0;

  const arrowSize = isWidth800 ? '60%' : '45%'; // Adjust size for screens smaller than 800px

  return (
    <ItemBarContainer>
      <ItemBarInfoContainer>
        <Heading3>{title}</Heading3>
        {isWidth800 ? (
          <PrimaryIconButton
            darkGreen
            icon={<ArrowForwardIcon fontSize="18px" />}
            aria-label="View All"
          />
        ) : (
          <PrimaryButton
            darkGreen
            variant="solid"
            padding={!isWidth800 ? '14px 24px' : '14px 12px'}
            height="48px"
            rightIcon={<ArrowForwardIcon fontSize="24px" />}
            onClick={onViewAllClick}
          >
            {t('auctions.viewAll')}
          </PrimaryButton>
        )}
      </ItemBarInfoContainer>

      <LoadingAnimation loading={loading} />

      {!loading && (
        <SliderContainer>
          {showArrows && hasPreviousPage && (
            <StyledNavigatorIconContainer
              style={{ left: '10px' }}
              onClick={() => slider?.slickPrev()}
              isWidth800={isWidth800}
            >
              <Icon
                as={SlArrowLeft}
                color="white"
                w={arrowSize}
                h={arrowSize}
              />
            </StyledNavigatorIconContainer>
          )}

          {showArrows && hasNextPage && (
            <StyledNavigatorIconContainer
              style={{ right: '10px' }}
              onClick={() => slider?.slickNext()}
              isWidth800={isWidth800}
            >
              <Icon
                as={SlArrowRight}
                color="white"
                w={arrowSize}
                h={arrowSize}
              />
            </StyledNavigatorIconContainer>
          )}

          <Slider
            {...settings}
            ref={(slider) => setSlider(slider)}
            beforeChange={handleBeforeChange}
          >
            {listings.map((listing) => (
              <SlideBox key={listing.id}>
                <ItemCard listing={listing} />
              </SlideBox>
            ))}
          </Slider>
        </SliderContainer>
      )}
    </ItemBarContainer>
  );
};

export default observer(ItemsShowcase);
