import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from '@chakra-ui/react';

import { Routes } from '../../router/routes';
import { AuctionsContainer } from '../../screens/styledComponents';
import { useTranslate } from '../../hooks/useTranslate';
import { useStore } from '../../hooks/useStore';
import { AuctionPageType } from '../../constants/types';
import { Breakpoints } from '../../constants/sizes';

import ItemsShowcase from './ItemsShowcase';

const AuctionsPreview = () => {
  const [loading, setLoading] = useState(false);

  const t = useTranslate();
  const navigate = useNavigate();

  const [isWidth800] = useMediaQuery([`(max-width: ${Breakpoints.Width800})`]);

  const {
    listingStore: {
      getPopularAuctions,
      popularAuctions,
      getEndingSoonAuctions,
      endingSoonAuctions,
      getRecentBuyNowListings,
      recentBuyNowListings,
    },
  } = useStore();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getPopularAuctions({ pageNo: 0, limit: 21 });
      await getEndingSoonAuctions({ pageNo: 0, limit: 21 });
      await getRecentBuyNowListings({ pageNo: 0, limit: 21 });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })();
  }, []);

  return (
    <AuctionsContainer isWidth800={isWidth800}>
      <ItemsShowcase
        title={t('auctions.popularAuctions')}
        onViewAllClick={() =>
          navigate(`${Routes.Auctions}?type=${AuctionPageType.popularAuctions}`)
        }
        listings={popularAuctions}
        loading={loading}
      />
      <ItemsShowcase
        title={t('auctions.endingSoon')}
        onViewAllClick={() =>
          navigate(`${Routes.Auctions}?type=${AuctionPageType.endingSoon}`)
        }
        listings={endingSoonAuctions}
        loading={loading}
      />
      <ItemsShowcase
        title={t('auctions.recentBuyItNow')}
        onViewAllClick={() =>
          navigate(`${Routes.Auctions}?type=${AuctionPageType.recentBuyItNow}`)
        }
        listings={recentBuyNowListings}
        loading={loading}
      />
    </AuctionsContainer>
  );
};

export default observer(AuctionsPreview);
