import React, { useEffect, useRef, useState } from 'react';
import { Input } from '@chakra-ui/react';
import {
  EncoreColors,
  IAuctionMessage,
} from '@Encore-FastStartup-Phase-Out/encore-shared';
import { observer } from 'mobx-react-lite';

import { WebAppColors } from '../../constants/colors';
import { CompleteListing } from '../../models/Listing';
import { useStore } from '../../hooks/useStore';

import { ChatsContainer, ChatWrapper } from './styledComponents';
import ChatItem from './ChatItem';

interface ChatProps {
  listing: CompleteListing;
}

const Chat = ({ listing }: ChatProps) => {
  const {
    uiStore: { openDownloadPopup },
  } = useStore();

  const [messages, setMessages] = useState<IAuctionMessage[]>([]);
  const chatsContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const scrollToBottom = () => {
    if (chatsContainerRef.current) {
      chatsContainerRef.current.scrollTop =
        chatsContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    setMessages(listing.auctionMessages);
    scrollToBottom();
  }, [listing]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleInputClick = () => {
    openDownloadPopup();

    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  return (
    <ChatWrapper>
      <ChatsContainer ref={chatsContainerRef}>
        {messages.map((message, index) => (
          <ChatItem key={index} message={message} />
        ))}
      </ChatsContainer>
      <Input
        ref={inputRef}
        variant="outline"
        placeholder="Say something"
        size="lg"
        style={{
          borderRadius: '12px',
          padding: '24px 24px',
          border: `1px solid ${WebAppColors.Gray50}`,
          color: EncoreColors.White,
          fontSize: '16px',
          lineHeight: '24px',
        }}
        _placeholder={{
          color: EncoreColors.White,
          fontSize: '16px',
          lineHeight: '24px',
        }}
        onClick={handleInputClick}
        isReadOnly
      />
    </ChatWrapper>
  );
};

export default observer(Chat);
