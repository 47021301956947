"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderTrackingScreenMessages = exports.buyerStatusMessages = exports.sellerStatusMessages = void 0;
var enums_1 = require("../enums");
// These objects are used to map the backend status to the frontend status for the order tracking message,
// the seller order status message, and the buyer order status message, and the order tracking screen message.
// The keys are the backend status and the values are the frontend status.
var commonStatusMessages = (_a = {},
    _a[enums_1.OrderBackendStatus.PENDING] = '',
    _a[enums_1.OrderBackendStatus.PAYMENT_PENDING] = '',
    _a[enums_1.OrderBackendStatus.PAYMENT_FAILED] = enums_1.OrderShippingStatus.ACTION_NEEDED,
    _a[enums_1.OrderBackendStatus.PAYMENT_CONFIRMED] = enums_1.OrderShippingStatus.AWAITING_SHIPPING,
    _a[enums_1.OrderBackendStatus.CONFIRMED] = enums_1.OrderShippingStatus.AWAITING_SHIPPING,
    _a[enums_1.OrderBackendStatus.SHIPPED] = enums_1.OrderShippingStatus.SHIPPED,
    _a[enums_1.OrderBackendStatus.SHIPPING_LABEL_PRINTED] = enums_1.OrderShippingStatus.LABEL_PRINTED,
    _a[enums_1.OrderBackendStatus.SHIPPING_MARKED_SHIPPED] = enums_1.OrderShippingStatus.MARKED_SHIPPED,
    _a[enums_1.OrderBackendStatus.INFORMATION_RECEIVED] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.DELAYED] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.DELIVERY_RESCHEDULED] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.DELIVERY_SCHEDULED] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.OUT_FOR_DELIVERY] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.PACKAGE_ACCEPTED] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.PACKAGE_ARRIVED] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.PACKAGE_DEPARTED] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.PACKAGE_FORWARD] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.PACKAGE_PROCESSED] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.PACKAGE_PROCESSING] = enums_1.OrderShippingStatus.IN_TRANSIT,
    _a[enums_1.OrderBackendStatus.DELIVERED] = enums_1.OrderShippingStatus.DELIVERED,
    _a[enums_1.OrderBackendStatus.SHIPPING_DELIVERED] = enums_1.OrderShippingStatus.DELIVERED,
    _a[enums_1.OrderBackendStatus.CANCELLED] = enums_1.OrderShippingStatus.CANCELLED,
    _a);
exports.sellerStatusMessages = __assign(__assign({}, commonStatusMessages), (_b = {}, _b[enums_1.OrderBackendStatus.PAYMENT_FAILED] = enums_1.OrderShippingStatus.ACTION_NEEDED, _b[enums_1.OrderBackendStatus.PAYMENT_CONFIRMED] = enums_1.OrderShippingStatus.AWAITING_SHIPPING, _b[enums_1.OrderBackendStatus.SHIPPING_LABEL_PRINTED] = enums_1.OrderShippingStatus.LABEL_PRINTED, _b[enums_1.OrderBackendStatus.SHIPPING_MARKED_SHIPPED] = enums_1.OrderShippingStatus.MARKED_SHIPPED, _b[enums_1.OrderBackendStatus.RETURN_TO_SENDER] = enums_1.OrderShippingStatus.RETURNED, _b[enums_1.OrderBackendStatus.PACKAGE_DISPOSED] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.OTHER] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.ADDRESS_ISSUE] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.CONTACT_CARRIER] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.DELIVERY_ATTEMPTED] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.LOCATION_INACCESSIBLE] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.NOTICE_LEFT] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.PACKAGE_DAMAGED] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.PACKAGE_HELD] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.PICKUP_AVAILABLE] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.RESCHEDULE_DELIVERY] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.PACKAGE_UNCLAIMED] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.PACKAGE_UNDELIVERED] = enums_1.OrderShippingStatus.ISSUE, _b[enums_1.OrderBackendStatus.PACKAGE_LOST] = enums_1.OrderShippingStatus.ISSUE, _b));
exports.buyerStatusMessages = __assign(__assign({}, commonStatusMessages), (_c = {}, _c[enums_1.OrderBackendStatus.PAYMENT_FAILED] = enums_1.OrderShippingStatus.AWAITING_BUYER_PAYMENT, _c[enums_1.OrderBackendStatus.PAYMENT_CONFIRMED] = enums_1.OrderShippingStatus.AWAITING_SHIPPING, _c[enums_1.OrderBackendStatus.SHIPPING_LABEL_PRINTED] = enums_1.OrderShippingStatus.LABEL_PRINTED, _c[enums_1.OrderBackendStatus.SHIPPING_MARKED_SHIPPED] = enums_1.OrderShippingStatus.MARKED_SHIPPED, _c[enums_1.OrderBackendStatus.RETURN_TO_SENDER] = enums_1.OrderShippingStatus.RETURNED, _c[enums_1.OrderBackendStatus.PACKAGE_DISPOSED] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.OTHER] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.ADDRESS_ISSUE] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.CONTACT_CARRIER] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.DELIVERY_ATTEMPTED] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.LOCATION_INACCESSIBLE] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.NOTICE_LEFT] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.PACKAGE_DAMAGED] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.PACKAGE_HELD] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.PICKUP_AVAILABLE] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.RESCHEDULE_DELIVERY] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.PACKAGE_UNCLAIMED] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.PACKAGE_UNDELIVERED] = enums_1.OrderShippingStatus.SHIPPED, _c[enums_1.OrderBackendStatus.PACKAGE_LOST] = enums_1.OrderShippingStatus.SHIPPED, _c));
exports.orderTrackingScreenMessages = __assign(__assign({}, commonStatusMessages), (_d = {}, _d[enums_1.OrderBackendStatus.PAYMENT_FAILED] = '', _d[enums_1.OrderBackendStatus.PAYMENT_CONFIRMED] = enums_1.OrderShippingStatus.PREPARING, _d[enums_1.OrderBackendStatus.SHIPPING_LABEL_PRINTED] = enums_1.OrderShippingStatus.PREPARING, _d[enums_1.OrderBackendStatus.SHIPPING_MARKED_SHIPPED] = enums_1.OrderShippingStatus.SHIPPED, _d[enums_1.OrderBackendStatus.RETURN_TO_SENDER] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.PACKAGE_DISPOSED] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.OTHER] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.ADDRESS_ISSUE] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.CONTACT_CARRIER] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.DELIVERY_ATTEMPTED] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.LOCATION_INACCESSIBLE] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.NOTICE_LEFT] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.PACKAGE_DAMAGED] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.PACKAGE_HELD] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.PICKUP_AVAILABLE] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.RESCHEDULE_DELIVERY] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.PACKAGE_UNCLAIMED] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.PACKAGE_UNDELIVERED] = enums_1.OrderShippingStatus.ISSUE, _d[enums_1.OrderBackendStatus.PACKAGE_LOST] = enums_1.OrderShippingStatus.ISSUE, _d));
