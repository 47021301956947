import React from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  IconButton,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import {
  IoIosPause,
  IoIosPlay,
  IoIosVolumeHigh,
  IoIosVolumeOff,
} from 'react-icons/io';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { WebAppColors } from '../../constants/colors';

interface ControlProps {
  onPlayPause: () => void;
  playing: boolean;
  played: number;
  onSeek: (value: number) => void;
  onSeekMouseUp: (value: number) => void;
  onVolumeChangeHandler: (value: number) => void;
  onVolumeSeekUp: (value: number) => void;
  volume: number;
  mute: boolean;
  onMute: () => void;
  duration: string;
  currentTime: string;
  onMouseSeekDown: (e: React.MouseEvent | React.TouchEvent) => void;
  controlRef: React.RefObject<HTMLDivElement>;
}

const ControlContainer = styled(Box)`
  border-radius: 12px;
  background-color: ${WebAppColors.Black60};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
`;

const CenterPlayButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const VideoWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  &:hover ${ControlContainer} {
    opacity: 1;
    visibility: visible;
  }
`;

const Control: React.FC<ControlProps> = ({
  onPlayPause,
  playing,
  played,
  onSeek,
  onSeekMouseUp,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  volume,
  mute,
  onMute,
  duration,
  currentTime,
  onMouseSeekDown,
  controlRef,
}) => {
  return (
    <VideoWrapper>
      <ControlContainer ref={controlRef}>
        <CenterPlayButton
          aria-label="Play/Pause"
          icon={playing ? <IoIosPause /> : <IoIosPlay />}
          onClick={onPlayPause}
          color={EncoreColors.Primary}
          fontSize="xl"
          variant="ghost"
          mx={4}
        />

        <VStack
          spacing={4}
          align="stretch"
          bottom="0"
          position="absolute"
          width="100%"
          left="0"
        >
          <Box w="full" px={4}>
            <Slider
              aria-label="progress-slider"
              value={played * 100}
              onChange={onSeek}
              onChangeEnd={onSeekMouseUp}
              onMouseDown={onMouseSeekDown}
            >
              <SliderTrack>
                <SliderFilledTrack bg={EncoreColors.Primary} />
              </SliderTrack>
              <SliderThumb boxSize={3} bg={EncoreColors.Primary} />
            </Slider>
          </Box>

          <Flex align="center" justify="space-between" px={4}>
            <Flex align="center">
              <IconButton
                aria-label="Play/Pause"
                icon={playing ? <IoIosPause /> : <IoIosPlay />}
                onClick={onPlayPause}
                color={EncoreColors.Primary}
                fontSize="xl"
                variant="ghost"
              />
              <IconButton
                aria-label="Mute"
                icon={mute ? <IoIosVolumeOff /> : <IoIosVolumeHigh />}
                onClick={onMute}
                color={EncoreColors.Primary}
                fontSize="xl"
                variant="ghost"
                ml={2}
              />
              <Slider
                aria-label="volume-slider"
                value={volume * 100}
                onChange={onVolumeChangeHandler}
                onChangeEnd={onVolumeSeekUp}
                colorScheme="green"
                width="100px"
                ml={2}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={3} />
              </Slider>
            </Flex>
            <Text color="white" fontSize="sm">
              {currentTime} / {duration}
            </Text>
          </Flex>
        </VStack>
      </ControlContainer>
    </VideoWrapper>
  );
};

export default Control;
