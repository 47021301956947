import {
  IAuctionMessage,
  endpoints,
} from '@Encore-FastStartup-Phase-Out/encore-shared';

import { BaseApi } from './index';

export class BidsApi extends BaseApi<IAuctionMessage> {
  constructor() {
    super(endpoints.bids.base);
  }

  async fetchListingAuctionMessages(listingId: string) {
    return this.fetch(endpoints.bids.listingBids(listingId));
  }
}
