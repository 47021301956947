import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ListingType } from '@Encore-FastStartup-Phase-Out/encore-shared';

import AuctionsPreview from '../components/itemBars/AuctionsPreview';
import { useStore } from '../hooks/useStore';
import VideoPlayer from '../components/video-player/VideoPlayer';
import ListingInfo from '../components/common/ListingInfo';
import Chat from '../components/chat/Chat';
import OpenInAppPopup from '../components/common/OpenInAppPopup';
import { useLegacyEffect } from '../hooks/useLegacyEffect';
import { getListingLink } from '../utils/adjustLinking';
import SellerListings from '../components/itemBars/SellerListings';
import LoadingAnimation from '../components/lottie/LoadingAnimation';

import { AuctionScreenWrapper } from './styledComponents';

const AuctionScreen = () => {
  const {
    listingStore: { currentListing, getListingById, fetchSellerListings },
    eventsStore: { unsubscribeToAllListingEvents },
    bidsStore: { listenToNewAuctionMessage },
  } = useStore();

  const { id, sharerId } = useParams<{ id: string; sharerId?: string }>();
  const location = useLocation();
  const [openInAppPopupLink, setOpenInAppPopupLink] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (id && (!currentListing?.id || currentListing?.id !== id)) {
        setLoading(true);
        await getListingById(id);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    })();
    const queryParams = new URLSearchParams(location.search);
    const sharerId = queryParams.get('sharerId');
    if (id && sharerId) {
      const link = getListingLink(id, sharerId);
      setOpenInAppPopupLink(link);
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      if (
        currentListing &&
        currentListing.listingType === ListingType.DEFAULT
      ) {
        setLoading(true);
        await fetchSellerListings(currentListing.user.id);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    })();
  }, [currentListing]);

  useLegacyEffect(() => {
    if (currentListing) {
      unsubscribeToAllListingEvents(currentListing.id);
      listenToNewAuctionMessage(currentListing);
    }

    return () => {
      if (currentListing) {
        unsubscribeToAllListingEvents(currentListing.id);
      }
    };
  });

  if (!currentListing) {
    return null;
  }

  return (
    <Box>
      {openInAppPopupLink && <OpenInAppPopup linkToOpen={openInAppPopupLink} />}
      <LoadingAnimation loading={loading} />
      {!loading && (
        <AuctionScreenWrapper>
          <>
            <ListingInfo listing={currentListing} />
            <VideoPlayer listing={currentListing} />
            {currentListing.listingType === ListingType.AUCTION ? (
              <Chat listing={currentListing} />
            ) : (
              <SellerListings />
            )}
          </>
        </AuctionScreenWrapper>
      )}
      <AuctionsPreview />
    </Box>
  );
};

export default observer(AuctionScreen);
