import React, { useEffect, useRef, useState } from 'react';
import {
  AuctionMessageType,
  IAuctionMessage,
} from '@Encore-FastStartup-Phase-Out/encore-shared';

import { UserImage, UserImageWrapper } from '../cards/styledComponents';
import { images } from '../images/images';

import {
  ChatItemContainer,
  ChatTextWrapper,
  MessageText,
  Username,
} from './styledComponents';

interface ChatItemProps {
  message: IAuctionMessage;
}

const ChatItem = ({ message }: ChatItemProps) => {
  const textWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isSingleLine, setIsSingleLine] = useState(true);

  useEffect(() => {
    const textWrapperElement = textWrapperRef.current;

    if (textWrapperElement) {
      setIsSingleLine(
        textWrapperElement.scrollHeight === textWrapperElement.clientHeight,
      );
    }
  }, [message]);

  return (
    <ChatItemContainer
      isBid={message.type === AuctionMessageType.Bid}
      alignCenter={isSingleLine}
    >
      <UserImageWrapper size={'7%'} mt={1}>
        <UserImage
          src={
            message.user?.avatar ? message.user?.avatar : images.defaultPicture
          }
          alt="User Profile Image"
        />
      </UserImageWrapper>
      {message.type === AuctionMessageType.Text ? (
        <ChatTextWrapper ref={textWrapperRef}>
          <Username>{message.user.userName}:</Username>
          <MessageText>{message.text}</MessageText>
        </ChatTextWrapper>
      ) : (
        <ChatTextWrapper ref={textWrapperRef}>
          <MessageText>{`${message.user.userName} bids `}</MessageText>
          <MessageText isBid>{`$${message.amount}`}</MessageText>
        </ChatTextWrapper>
      )}
    </ChatItemContainer>
  );
};

export default ChatItem;
