import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Icon } from '@chakra-ui/react';
import { ListingType } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { FiClock } from 'react-icons/fi';
import { observer } from 'mobx-react-lite';

import { CompleteListing } from '../../models/Listing';
import { Routes } from '../../router/routes';
import { useStore } from '../../hooks/useStore';
import { BodyText3, BodyText4, BodyText5 } from '../typography/BodyTexts';
import { ReactComponent as Star } from '../../assets/svg/star.svg';
import {
  RatingContainer,
  SellerInfoContainer,
  SellerInfoWrapper,
} from '../../screens/styledComponents';
import { images } from '../images/images';
import { useTranslate } from '../../hooks/useTranslate';

import {
  BadgesContainer,
  CardBadge,
  GreenText,
  ImageContainer,
  ItemContainer,
  ItemImage,
  PriceContainer,
  SellerCardName,
  SellerItemInfoWrapper,
  UserImage,
  UserImageWrapper,
} from './styledComponents';
import Timer from './Timer';

interface SellerItemCardProps {
  listing: CompleteListing;
}

const SellerItemCard = ({ listing }: SellerItemCardProps) => {
  const {
    listingStore: { setCurrentListing },
  } = useStore();
  const navigate = useNavigate();
  const t = useTranslate();

  const [alert, setAlert] = useState(false);

  const onCardClick = () => {
    navigate(`${Routes.Listing}/${listing.id}`);
    setCurrentListing(listing);
  };

  return (
    <ItemContainer key={`seller-listing-${listing.id}`}>
      <ImageContainer onClick={onCardClick} width="auto">
        <ItemImage src={listing.thumbnail} alt="Auction Item" width="auto" />
      </ImageContainer>
      <SellerItemInfoWrapper>
        <BodyText3>{listing.title}</BodyText3>
        {listing.listingType === ListingType.AUCTION ? (
          <>
            <SellerInfoContainer>
              <UserImageWrapper size={'20%'}>
                <UserImage
                  src={
                    listing.user?.avatar
                      ? listing.user?.avatar
                      : images.defaultPicture
                  }
                  alt="User Profile Image"
                />
              </UserImageWrapper>
              <SellerInfoWrapper>
                <SellerCardName>{listing.user?.userName}</SellerCardName>
                <RatingContainer>
                  <Box as={Star} width={4} height={4} />
                  <BodyText5 textTransform="none">
                    {`${listing.user?.avgRate || 0} (${
                      listing.user?.totalRatings || 0
                    })`}
                  </BodyText5>
                </RatingContainer>
              </SellerInfoWrapper>
            </SellerInfoContainer>
            <BadgesContainer>
              <CardBadge alert={alert}>
                <Icon as={FiClock} />
                <Timer
                  endTime={listing.endTime ? listing.endTime : 0}
                  format="dhms"
                  setAlert={setAlert}
                  style={{
                    fontSize: '12px',
                  }}
                />
              </CardBadge>

              <CardBadge>
                <BodyText4
                  textTransform="none"
                  fontWeight="800"
                >{`🔥 ${listing.auctionMessages.length} ${t('common.bids')}`}</BodyText4>
              </CardBadge>
            </BadgesContainer>
          </>
        ) : (
          <>
            <PriceContainer>
              <GreenText>{`$${listing.price}`}</GreenText>
              <BodyText5>{t('auction.orBestOffer')}</BodyText5>
            </PriceContainer>
            <SellerInfoContainer>
              <UserImageWrapper size={'20%'}>
                <UserImage
                  src={
                    listing.user?.avatar
                      ? listing.user?.avatar
                      : images.defaultPicture
                  }
                  alt="User Profile Image"
                />
              </UserImageWrapper>
              <SellerInfoWrapper>
                <SellerCardName>{listing.user?.userName}</SellerCardName>
                <RatingContainer>
                  <Box as={Star} width={4} height={4} />
                  <BodyText5 textTransform="none">
                    {`${listing.user?.avgRate || 0} (${
                      listing.user?.totalRatings || 0
                    })`}
                  </BodyText5>
                </RatingContainer>
              </SellerInfoWrapper>
            </SellerInfoContainer>
          </>
        )}
      </SellerItemInfoWrapper>
    </ItemContainer>
  );
};

export default observer(SellerItemCard);
