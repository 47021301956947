import React from 'react';
import { IUser } from '@Encore-FastStartup-Phase-Out/encore-shared';
import { useMediaQuery } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { images } from '../images/images';
import { Heading3 } from '../typography/Headlines';
import { BodyTextLarge2 } from '../typography/BodyTexts';
import PrimaryButton from '../buttons/PrimaryButton';
import { ReactComponent as Star } from '../../assets/svg/star.svg';
import { useTranslate } from '../../hooks/useTranslate';
import { useStore } from '../../hooks/useStore';
import { Breakpoints } from '../../constants/sizes';

import {
  ActionButtons,
  Avatar,
  AvatarWrapper,
  HeaderSection,
  ProfileSection,
  RatingSection,
  StarIcon,
  UserInfo,
} from './styledComponents';
import SellerInfo from './SellerInfo';

type SellerHeaderProps = {
  currentUser: IUser;
};

const SellerHeader = ({ currentUser }: SellerHeaderProps) => {
  const t = useTranslate();
  const {
    uiStore: { openDownloadPopup },
  } = useStore();

  const [isWidth1080] = useMediaQuery([
    `(max-width: ${Breakpoints.Width1080})`,
  ]);

  return (
    <HeaderSection>
      <ProfileSection>
        <AvatarWrapper>
          <Avatar
            src={
              currentUser?.avatar ? currentUser?.avatar : images.defaultPicture
            }
            alt="User Profile Image"
          />
        </AvatarWrapper>
        <UserInfo>
          <Heading3>{'@' + currentUser?.userName}</Heading3>
          {!isWidth1080 && (
            <RatingSection>
              <StarIcon as={Star} />
              <BodyTextLarge2>
                {`${currentUser?.avgRate || 0} (${currentUser?.totalRatings || 0})`}
              </BodyTextLarge2>
            </RatingSection>
          )}
        </UserInfo>
        {isWidth1080 && (
          <PrimaryButton
            green
            padding={'12px 16px'}
            borderRadius={'12px'}
            onClick={openDownloadPopup}
          >
            {t('user.follow')}
          </PrimaryButton>
        )}
      </ProfileSection>

      <SellerInfo
        nrOfFollowers={currentUser.totalFollowers || 0}
        nrOfFollowing={currentUser.totalFollowings || 0}
        nrOfReviews={currentUser.totalRatings}
        description={currentUser.profileDescription}
      />

      {!isWidth1080 && (
        <ActionButtons>
          <PrimaryButton
            green
            padding={'12px 32px'}
            borderRadius={'12px'}
            onClick={openDownloadPopup}
          >
            {t('user.follow')}
          </PrimaryButton>
          <PrimaryButton
            outlined
            padding={'12px 32px'}
            borderRadius={'12px'}
            onClick={openDownloadPopup}
          >
            {t('user.message')}
          </PrimaryButton>
        </ActionButtons>
      )}
    </HeaderSection>
  );
};

export default observer(SellerHeader);
