import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../hooks/useStore';
import SellerItemCard from '../cards/SellerItemCard';
import { useTranslate } from '../../hooks/useTranslate';

import {
  MoreText,
  SellerItemsContainer,
  SellerItemsListWrapper,
} from './styledComponents';

const SellerListings = () => {
  const {
    listingStore: { userActiveListings, currentListing },
  } = useStore();

  const t = useTranslate();

  return (
    <SellerItemsListWrapper>
      <MoreText>{`${t('auction.moreFrom')} '${currentListing?.user.userName}'`}</MoreText>
      <SellerItemsContainer>
        {userActiveListings.map((listing) => (
          <SellerItemCard key={listing.id} listing={listing} />
        ))}
      </SellerItemsContainer>
    </SellerItemsListWrapper>
  );
};

export default observer(SellerListings);
