import styled from '@emotion/styled';
import { Badge, Box } from '@chakra-ui/react';
import { EncoreColors } from '@Encore-FastStartup-Phase-Out/encore-shared';

import { Heading3 } from '../components/typography/Headlines';
import { WebAppColors } from '../constants/colors';

export const HomeScreenWrapper = styled(Box)<{ isWidth800?: boolean }>`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  padding: ${(props) =>
    props.isWidth800 ? '20px 4px 40px 40px' : '80px 8px 40px 80px'};
`;

export const InfoWrapper = styled(Box)<{ isWidth650?: boolean }>`
  width: ${(props) => (props.isWidth650 ? '100%' : '45%')};
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
  margin-top: 5vh;
`;

export const PrimaryTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AuctionsContainer = styled(Box)<{ isWidth800?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: ${(props) => (props.isWidth800 ? '0 30px' : ' 0 40px;')};
  margin-bottom: 100px;
`;

export const AuctionsPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 20px 41px;
`;

export const AuctionsPageActionsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 20px;
`;

export const TitleText = styled(Heading3)`
  width: 100%;
  text-align: center;
`;

export const AuctionsGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
`;

export const UserAuctionsGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;

  @media (max-width: 650px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 16px;
  }
`;

export const AuctionScreenWrapper = styled(Box)`
  display: flex;
  padding: 80px;
  justify-content: space-between;
`;

export const AuctionInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: fit-content;
  width: 30%;
`;

export const AuctionVideoWrapper = styled(Box)`
  display: flex;
  width: 35%;
  height: 80vh;
`;

export const SellerActionsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

export const SellerInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const SellerInfoContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const RatingContainer = styled(Box)`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
`;

export const DescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const AuctionInfoFooter = styled(Box)`
  display: flex;
  gap: 9px;
`;

export const FooterBadges = styled(Badge)<{ time?: boolean }>`
  background: ${(props) =>
    props.time ? WebAppColors.Red15 : WebAppColors.Gray1};
  color: ${EncoreColors.White};
  border: ${(props) =>
    props.time
      ? `1px solid ${WebAppColors.Red70}`
      : `1px solid ${WebAppColors.Gray50}`};
  border-radius: 12px;
  padding: 12px 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  max-width: 30%;
  min-width: ${(props) => (props.time ? '30%' : 'fit-content')};
`;
