export const endpoints = {
  users: {
    base: '/public/users',
    byId: (id: string) => `/public/users/${id}`,
  },
  listings: {
    base: '/public/listings',
    auctionsEndingSoon: '/public/listings/auctions-ending-soon',
    popularAuctions: '/public/listings/popular-auctions',
    recentBuyNowListings: '/public/listings/recent-buy-now-listings',
    byId: (id: string) => `/public/listings/listing-by-id/${id}`,
    activeByUserId: (userId: string) =>
      `/public/listings/user-active-listings/${userId}`,
    soldByUserId: (userId: string) =>
      `/public/listings/user-sold-listings/${userId}`,
    buyItNowByUserId: (userId: string) =>
      `/public/listings/user-buy-now-listings/${userId}`,
  },
};
