import React from 'react';
import { Divider, Text, VStack } from '@chakra-ui/react';

import { Heading5 } from '../typography/Headlines';

type InfoCardProps = {
  title: string;
  description: string;
};

export const InfoCard = ({ title, description }: InfoCardProps) => (
  <>
    <VStack spacing={4} align="start">
      <Heading5>{title}</Heading5>
      <Text
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </VStack>
    <Divider my={6} />
  </>
);
