import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

import HomeScreen from '../screens/HomeScreen';
import { PrivacyPolicyScreen } from '../screens/PrivacyPolicyScreen';
import { TermsScreen } from '../screens/TermsScreen';
import AuctionsScreen from '../screens/AuctionsScreen';
import AuctionScreen from '../screens/AuctionScreen';
import SellerProfileScreen from '../screens/SellerProfileScreen';

import { Routes } from './routes';
import NavigationWrapper from './NavigationWrapper';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={Routes.Home}
        element={<NavigationWrapper children={<HomeScreen />} />}
      />
      <Route
        path={Routes.Auctions}
        element={<NavigationWrapper children={<AuctionsScreen />} />}
      />
      <Route
        path={`${Routes.Listing}/:id`}
        element={<NavigationWrapper children={<AuctionScreen />} />}
      />
      <Route
        path={`${Routes.Seller}/:id`}
        element={<NavigationWrapper children={<SellerProfileScreen />} />}
      />
      <Route path={Routes.PrivacyPolicy} element={<PrivacyPolicyScreen />} />
      <Route path={Routes.Terms} element={<TermsScreen />} />
      <Route path="*" element={<Navigate to={Routes.Home} replace />} />
    </>,
  ),
);
